var vue_open_ai_message = new Vue({
    el: '#rakuya_url_search',
    data: {
        url: '',
    },
    mounted: function () {
    },
    methods: {
        searchUrl() {
            if(this.url != ''){
                window.open('https://hub.c21.tw/rise/link?url=' + this.url);
                this.url = '';
            }
        },
    }
});
