const pathname = window.location.pathname;
function show_terms(terms_code){
    $.ajax({
        url: '/get_content/'+terms_code,
        type: 'GET',
        dataType: 'json',
        success: function (response) {
            if (response.terms_id !== undefined) {
                Swal.fire({
                    title: response.title,
                    imageUrl: '/assets/images/logo.png',
                    imageWidth: 90,
                    imageHeight: 90,
                    html: '<textarea class="consent_terms" readonly style="height:300px;display:block;width:100%;">' + response.content + '</textarea>',
                    showCancelButton: true,
                    allowOutsideClick: false,
                    confirmButtonText: '同意',
                    cancelButtonText: '不同意',
                    customClass: 'newSwal',
                    didOpen: (toast) => {
                        let $consent_terms = $('.consent_terms', '.newSwal');
                        if ($consent_terms.length) {
                            $('.consent_terms', '.newSwal').each(function () {
                                var $newSwal = $(this).parents('.newSwal');

                                $('.swal2-confirm', $newSwal).prop('disabled', true);
                                $('.consent_terms', $newSwal).scroll(function () {
                                    if (($(this).prop('scrollHeight') - $(this).scrollTop() - 10) < $(this).height()) {
                                        $('.swal2-confirm', $newSwal).prop('disabled', false);
                                    }
                                });
                                setTimeout(function () {
                                    $('.swal2-confirm', $newSwal).prop('disabled', false);
                                }, 5000);
                            });
                        }
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        $.ajax({
                            url: '/terms/content',
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                'terms_id': response.terms_id,
                                'terms_code': response.terms_code,
                                'version': response.version,
                                'content': true,
                                '_token': $('meta[name="csrf-token"]').attr('content'),
                            },
                            success: function (response) {
                                console.log('同意使用條款', response);
                            },
                            error: function (xhr, status, error) {
                                console.error('同意條款錯誤', error);
                            }
                        });
                    } else {
                        $.post('/content', {consent: false});
                        window.location.href = '/logout';
                    }
                })
            }
            if(response.status !== undefined){
                if(response.status){
                    console.log('已同意使用條款');
                }else{
                    console.log('已同意條款錯誤');
                }
            }
        },
        error: function (xhr, status, error) {
            console.error('同意條款錯誤', error);
        }
    });
}
if(pathname != '/landing'){
    show_terms('USE');
}


