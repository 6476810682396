/**
 * CopyButton.js
 * Html 加上 .copy-mess 包 .mess，會複製內層.mess 的訊息
 */

// Copy btn 原始碼
const btn_htm = `<div class="message-copy text-end">
  <div class="tooltip-up"><span class="tooltiptext">複製</span>
    <button type="button" class="btn btn-outline-primary btn-sm border-0 p-0 hover: p-1 copy-btn">
      <i class="fa fa-copy"></i></button>
  </div>
</div>`;

// 判斷該DOM有沒有加上按鈕，沒有加上按鈕就加上
function add_copy_btn(_this){
  $(_this).each(function(){
    if ($(this).find('.copy-btn').length == 0) {
      $(this).prepend(btn_htm);
    }
  });
}

$(function(){ // 頁面 DOM 完成後先執行一次
  add_copy_btn('.copy-mess');
}).on('DOMNodeInserted', '.copy-mess', function(){  // 監聽有插入 .copy-mess 執行一次
  add_copy_btn('.copy-mess');
}).on('click', '.copy-btn', function () { // 綁定所有 .copy-btn 加入 click事件(只加入一次，不會重複加)
  var $copy_mess = $(this).parents('.copy-mess'); // 點下去的btn 按鈕父層 .copy-mess
  var $tooltiptext = $('.tooltiptext', $copy_mess); // 即 .copy-mess 底下的 .tooltiptext
  var $mess = $('.mess', $copy_mess);  // 即 .copy-mess 底下的 .mess

  try {
    navigator.clipboard.writeText($mess.text());  // 複製 按下按紐相對應 .mess 的文字
    // .tooltiptext 文字改為'已複製',並顯示、延遲2000毫秒、停止動畫但不暫停狀態、動畫fadeOut線性執行1000毫秒
    $tooltiptext.text('已複製').show().delay(2000).stop(true, false).fadeOut(1000);
  } catch (err) {
    console.error('文本複製失敗：', err);
  }
});
