export const CameraModule = {
  startCamera: async function (videoEl) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoEl.srcObject = stream;
      videoEl.play();
      return stream;
    } catch (err) {
      console.log("getUserMedia error", err);
      throw err;
    }
  },

  stopCamera: function (stream) {
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  },

  takePhoto: function (videoEl, canvasEl) {
    const ctx = canvasEl.getContext("2d");
    canvasEl.width = videoEl.videoWidth;
    canvasEl.height = videoEl.videoHeight;
    ctx.drawImage(videoEl, 0, 0, canvasEl.width, canvasEl.height);
    return canvasEl.toDataURL();
  },
};
