$(function(){
    var anchor = location.hash;
    if(anchor != ''){
        let $aria_c = $('[aria-controls]');
        if($('[aria-controls][href="' + anchor + '"]').length > 0){
            $aria_c.removeClass('active');
            $('[role="tabpanel"]').removeClass('active').removeClass('show');
            $('[aria-controls][href="' + anchor + '"]').addClass('active');
            $(anchor).addClass('active').addClass('show');
        }
    }
}).on('click', '[aria-controls][href]', function(){
    location.hash = $(this).attr('href');
});