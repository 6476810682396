import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


class Base64UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Image = reader.result.split(',')[1];
                resolve({default: `data:image/png;base64,${base64Image}`});
            };
            reader.onerror = reject;
            this.loader.file.then(file => reader.readAsDataURL(file));
        });
    }

    abort() {
        // Handle abort
    }
}

function Base64UploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new Base64UploadAdapter(loader);
    };
}

$(document).ready(function () {
    let $editor = $('.editor');
    if($editor.length > 0){
        ClassicEditor
            .create(document.querySelector('.editor'), {
                extraPlugins: [
                    Base64UploadAdapterPlugin,
                ],
                toolbar: {
                    items: [
                        'heading', '|', 'bold', 'italic', 'link', 'bulletedList',
                        'numberedList', '|', 'blockQuote',
                        'insertTable', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo'
                    ]
                },
                language: 'zh',
                image: {
                    resizeUnit: "%",
                    resizeOptions: [{
                        name: 'resizeImage:original',
                        value: null
                    },
                        {
                            name: 'resizeImage:50',
                            value: '50'
                        },
                        {
                            name: 'resizeImage:75',
                            value: '75'
                        }],
                    toolbar: [
                        'imageStyle:inline', 'imageStyle:wrapText', 'imageStyle:breakText', '|',
                        'toggleImageCaption', 'imageTextAlternative'
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                }
            })
            .then(editor => {
                window.myEditor = editor;
            })
            .catch(error => {
                console.error(error);
            });
    }
})
