// 偵測手機板時加上 overflow
function checkMobileWidth(){
    const screenWidth = window.innerWidth;
    const htmlEl = document.querySelector('html');
    const elementsWithClassHtss = document.querySelectorAll('.m-html-overflow-hide');

    if(elementsWithClassHtss.length > 0){
        if(screenWidth <= 480) {
            htmlEl.style.overflow = 'hidden';
        } else {
            htmlEl.style.overflow = 'auto';
        }
    }

}
// 初始化偵測
checkMobileWidth()

// 監聽螢幕變化
window.addEventListener('resize',checkMobileWidth)
