$(function () {
    $('.nav-submenu', '.dropdown:not(.active)').hide();
}).on('click', '.dropdown:not(.open)', function () {
    var $this = $(this);
    $this.siblings('.dropdown').each(function () {
        $('.nav-submenu', $(this)).stop(true, false).slideUp();
        $(this).removeClass('active');
    });
    if ($this.hasClass('active')) {
        $('.nav-submenu', $this).stop(true, false).slideUp();
        $this.removeClass('active');
    } else {
        $('.nav-submenu', $this).stop(true, false).slideDown();
        $this.addClass('active');
    }
});