import zhHant from 'https://cdn.jsdelivr.net/npm/vuetify@2.6.14/lib/locale/zh-Hant.js'

const app = new Vue({
    el: '#auth_user_table',
    data:() => ({
        options: {},
        loading: true,
        total: 0,
        rows: [],
        itemsPerPage: 100,
        totalPageVisible: 9,
        pageCount: 0,
        page: 1,
        headers: [
            {text: '加盟店代號', value: 'app_metadata.StoreID', sortable: false},
            {text: '經紀人編號', value: 'app_metadata.EmployeeID', sortable: false},
            {text: '姓名', value: 'name', sortable: false},
            {text: 'email', value: 'email', sortable: false},
            {text: '手機', value: 'user_metadata.contact.phone', sortable: false},
            {text: '照片', value: 'picture', sortable: false},
            {text: '註冊時間', value: 'created_at', sortable: false},
            {text: '最後更新時間', value: 'updated_at', sortable: false},
            {text: '最後登入時間', value: 'last_login', sortable: false},
            {text: '登入次數', value: 'logins_count', sortable: false},
            {text: '帳號關閉', value: 'blocked', sortable: false},
            // {text: '最後登入IP', value: 'last_ip', sortable: false},
        ],
        expanded: [],
    }),
    watch: {
        options: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    methods: {
        getDataFromApi () {
            this.loading = true
            this.dataApiCall().then(json => {
                json.users.forEach(function(part, index) {
                    if(this[index].picture.indexOf('https://s.gravatar.com/') >= 0){
                        this[index].picture = '無'
                    }else{
                        this[index].picture = '有'
                    }
                    if(this[index].blocked){
                        this[index].blocked = '關閉'
                    }else{
                        this[index].blocked = ''
                    }

                    // this[index].created_at = this[index].created_at.slice(0, 19).replace('T', ' ');
                    // this[index].updated_at = this[index].updated_at.slice(0, 19).replace('T', ' ');
                    // this[index].last_login = this[index].last_login.slice(0, 19).replace('T', ' ');
                }, json.users);
                this.rows = json.users
                this.total = json.total
                this.loading = false
            })
                .catch(async (res) => {
                    const json = await res.json()
                    swal.fire("錯誤", json.message, 'error')
                })
        },
        dataApiCall() {
            const params = new URLSearchParams(this.options)

            return fetch('/web_api/auth_users.json?' + params, {
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                method: "GET"
            }).then(res => {
                if (res.ok){
                    return res.json()
                }else{
                    return Promise.reject(res)
                }
            })
        },
        notify(msg) {
            $.notify({
                    message: msg
                },
                {
                    type: 'primary',
                    allow_dismiss: false,
                    newest_on_top: false,
                    mouse_over: false,
                    showProgressbar:false,
                    spacing:10,
                    timer: 500,
                    placement:{
                        from:'top',
                        align:'left'
                    },
                    offset:{
                        x:30,
                        y:30
                    },
                    delay: 1000,
                    z_index: 10000,
                    animate: {
                        enter: 'animated fadeIn',
                        exit: 'animated bounce'
                    }
                })
        },
        confirmDestroy(item, index) {
            swal.fire({
                title: "確認操作",
                text: `確認要刪除ID: ${item.id} 這筆資料嗎？`,
                showCancelButton: true,
                confirmButtonText: "刪除",
                cancelButtonText: "取消"
            })
                .then(result => {
                    if (result.isConfirmed)
                        this.remove(item, index)
                })
        },
        formatTaiwanDate(date) {
            const d = new Date(date)
            return `${d.getFullYear() - 1911}/${(d.getMonth() + 1).toString().padStart(2, "0")}/${(d.getDate()).toString().padStart(2, "0")}`
        }
    },
    vuetify: new Vuetify({
        lang: {
            locales: { zhHant },
            current: 'zhHant'
        }
    })
})
